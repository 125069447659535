import { Component, OnInit, ViewChild } from '@angular/core';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { MatSidenav } from '@angular/material/sidenav';
import {
  MatLegacySnackBar as MatSnackBar,
  MatLegacySnackBarConfig as MatSnackBarConfig
} from '@angular/material/legacy-snack-bar';
import {
  ActivatedRoute,
  NavigationCancel,
  NavigationEnd,
  NavigationError,
  NavigationStart,
  Router,
  RouterEvent,
  RouterState
} from '@angular/router';

import amplitude from 'amplitude-js';
import { NGXLogger } from 'ngx-logger';
import { Observable } from 'rxjs';
import { filter, map, takeUntil, tap, withLatestFrom } from 'rxjs/operators';

import { environment } from '../environments/environment';
import { AuthService } from './core/auth.service';
import { BaseClass } from './core/base-class';
import { SessionService } from './core/session.service';

import { ProfilePopupComponent } from './profile-popup/profile-popup.component';

import { CustomLogMonitor } from './shared/custom-log-monitor';
import * as Notification from './shared/model/notification';
import { Company } from './shared/model/company';
import { ESpecificItem } from './components/sidebar/entities/sidebar.enums';

import { Analytics, EVENT } from './services/analytics';
import { NotificationService } from './services/notification.service';
import { TitleService } from './services/title.service';
import { UpdateService } from './services/update.service';
import { SidebarService } from './components/sidebar/sidebar.service';

import { SimpleDialogComponent } from './components/simple-dialog/simple-dialog.component';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent extends BaseClass implements OnInit {
  @ViewChild('sidenavMenu', { static: true }) sidenavMenu: MatSidenav;
  @ViewChild('sidenavNotifications', { static: true })
  sidenavNotifications: MatSidenav;

  profileDialogRef: MatDialogRef<ProfilePopupComponent>;
  loading = false;
  updateAvailable = false;
  maintenanceDialogRef: MatDialogRef<SimpleDialogComponent>;
  company$: Observable<Company>;
  notificationPopup = false;

  isHandset$: Observable<boolean> = this.breakpointObserver
    .observe(Breakpoints.Handset)
    .pipe(map((result) => result.matches));

  constructor(
    private analytics: Analytics,
    private breakpointObserver: BreakpointObserver,
    private dialog: MatDialog,
    private logger: NGXLogger,
    private router: Router,
    private snackBar: MatSnackBar,
    private titleService: TitleService,
    private updateService: UpdateService,
    public authService: AuthService,
    public notificationService: NotificationService,
    public sessionService: SessionService,
    public sidebarService: SidebarService,
  ) {
    super();
    window['pdfWorkerSrc'] = '/assets/js/pdf.worker.min.mjs';

    this.logger.registerMonitor(new CustomLogMonitor(analytics));

    this.company$ = sessionService.currentCompany$;
  }

  ngOnInit(): void {
    amplitude.getInstance().init(environment.amplitudeApiKey);

    this.analytics.startTracking();
    this.analytics.event(EVENT.APP_INIT, { version: environment.version });

    this.updateService.detection();
    this.router.events
      .pipe(
        tap((event) => {
          switch (true) {
            case event instanceof NavigationStart: {
              this.loading = true;
              break;
            }
            case event instanceof NavigationEnd:
            case event instanceof NavigationCancel:
            case event instanceof NavigationError: {
              setTimeout(() => (this.loading = false));
              break;
            }
            default: {
              break;
            }
          }
        }),
        filter((event) => event instanceof NavigationEnd),
        withLatestFrom(this.isHandset$),
      )
      .subscribe(([t, isHandset]) => {
        const title = this.getTitle(
          this.router.routerState,
          this.router.routerState.root,
        );
        this.titleService.setTitle(title[title.length - 1]);
        this.analytics.pageView((t as RouterEvent)?.url);
        this.closeNotifications();

        if (isHandset) {
          this.sidenavMenu.close();
        }
      });

    this.sidebarService.toggleMenu$.subscribe(() => this.sidenavMenu.toggle());

    this.sidebarService.changedItem$.subscribe((itemType: ESpecificItem) => {
      switch (itemType) {
        case ESpecificItem.profile:
          this.sidenavNotifications.close();
          break;
        default:
          this.sidenavNotifications.close();
      }
    });

    this.notificationService.notifications$
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((notifications: Notification.Interface[]) => {
        this.notificationPopup = !!notifications?.length;
        setTimeout(() => {
          this.notificationPopup = false;
        }, 30000);
      });

    this.notificationService.messagesSubj
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((message) => {
        this.openSnackBar(message, 'Fechar');
      });
  }

  getTitle(state: RouterState, parent: ActivatedRoute): string[] {
    const data = [];
    if (parent?.snapshot?.data?.title) {
      data.push(parent.snapshot.data.title);
    }

    if (state && parent) {
      data.push(...this.getTitle(state, state['firstChild'](parent)));
    }
    return data;
  }

  openSnackBar(message: string, action?: string): void {
    const config = new MatSnackBarConfig();
    config.panelClass = ['error'];
    config.duration = 5000;

    this.snackBar.open(message, action ? action : undefined, config);
  }

  onActivate(): void {
    window.scroll(0, 0);
  }

  closeNotifications(): void {
    this.sidebarService.setSpecificItem();
    this.sidenavNotifications.close();
  }

  toggleProfile(): void {
    if (this.profileDialogRef) {
      this.profileDialogRef.close();
    } else {
      this.profileDialogRef = this.dialog.open(ProfilePopupComponent, {
        position: { bottom: '54px', left: '150px' },
        backdropClass: 'profile-backdrop',
        autoFocus: false,
        panelClass: 'zero-padding-dialog',
      });

      this.profileDialogRef.afterClosed().subscribe(() => {
        this.profileDialogRef = undefined;
        this.sidebarService.setSpecificItem(undefined);
      });
    }
  }
}
