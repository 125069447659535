import {inject} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivateFn, Router, RouterStateSnapshot, UrlTree} from '@angular/router';

import {Observable} from 'rxjs';
import {map, take} from 'rxjs/operators';

import {SessionService} from '../core/session.service';

export const BetaGuard: CanActivateFn = (
  route: ActivatedRouteSnapshot,
  state: RouterStateSnapshot
): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree => {
  const router = inject(Router);
  const sessionService = inject(SessionService);
  const closecareRegex = /^[A-Za-z0-9._%+-]+@closecare\.com\.br$/;
  const restrictedHostname = window.location.hostname.includes('beta.closecare.com.br');

  return !restrictedHostname || sessionService.loggedInUser$.pipe(
    take(1),
    map(user => {
      if (!user) {
        return true;
      }
      return closecareRegex.test(user?.email) || router.parseUrl('/restricted');
    })
  );
}
