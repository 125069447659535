<header class="d-flex justify-content-between align-items-center">
  <h3 mat-dialog-title class="mt-0 mb-0">Configurações do usuário</h3>
  <button
    mat-icon-button
    class="small-circle-button"
    color="primary"
    (click)="close()"
  >
      <mat-icon>close</mat-icon>
  </button>
</header>
<main mat-dialog-content class="mt-4">
  <h4>Washington Carvalho dos Santos da Silva Neves</h4>
  <section>
    <table class="user-data">
      <tr>
        <th>E-mail</th>
        <th>Perfil</th>
      </tr>
      <tr>
        <td>fulanosantossilva&#64;email.com.br</td>
        <td>Gestor</td>
      </tr>
      <tr>
        <th>Acesso</th>
        <th>Equipe</th>
      </tr>
      <tr>
        <td>Região do funcionário</td>
        <td>Equipe 1</td>
      </tr>
    </table>
  </section>
  <mat-divider class="w-100 mt-3 mb-4"></mat-divider>
  <section>
    <h4 class="mb-0">Notificações</h4>
    <p class="mt-2">Personalize as notificações de email que você recebe da Closecare</p>
    <h5 class="mb-2">Notificar-me sobre:</h5>
    <div class="d-flex flex-column justify-content-start gap-8">
      <mat-checkbox color="primary">
        Atestados pendentes
      </mat-checkbox>
      <mat-checkbox color="primary">
        Atestados do último dia útil
      </mat-checkbox>
      <mat-checkbox color="primary">
        Relatório semanal de métricas
      </mat-checkbox>
      <mat-checkbox color="primary">
        Erros de integração
      </mat-checkbox>
    </div>
  </section>
</main>
<footer mat-dialog-actions class="py-0 d-flex flex-column">
  <mat-divider class="w-100 mt-4 mb-4"></mat-divider>
  <div class="w-100 d-flex justify-content-end align-items-center">
    <button mat-stroked-button color="primary" class="px-4 mr-2">
      Cancelar
    </button>
    <button mat-flat-button color="primary" class="px-4">
      Salvar alterações
    </button>
  </div>
</footer>
