import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { CompanyContainerComponent } from './company-container/company-container.component';
import { CompanyPickerComponent } from './company-picker/company-picker.component';
import { PermissionsGuard } from './core/permissions.guard';
import * as Roles from './core/roles';
import { NotFoundComponent } from './pages/not-found/not-found.component';
import { RestrictedComponent } from './pages/restricted/restricted.component';
import { AuthGuard } from './services/auth.guard';
import { BetaGuard } from './services/beta.guard';
import { CanActivateChildGuard } from './services/can-activate-child.guard';
import { CompanyGuard } from './services/company.guard';
import { DashboardResolverService } from './services/dashboard-resolver-service';
import { DefaultCompanyGuard } from './services/default-company.guard';

import { DashboardComponent } from './dashboard/dashboard.component';
import { HomeComponent } from './pages/main-dashboard/home.component';
import { SamlRedirectGuard } from './guards/saml-redirect.guard';
import { CompanyPickerGuard } from './guards/company-picker.guard';

const routes: Routes = [
  {path: '', redirectTo: '/c/default', pathMatch: 'full'},
  {
    path: 'c/default',
    children: [
      {
        path: '**',
        component: CompanyPickerComponent,
        canActivate: [AuthGuard, DefaultCompanyGuard, BetaGuard],
        data: {title: 'Escolher empresa'}
      }
    ],
  },
  {
    path: 'c/:company_id',
    component: CompanyContainerComponent,
    canActivate: [AuthGuard, CompanyGuard, BetaGuard],
    canActivateChild: [CanActivateChildGuard],
    children: [
      {
        path: '',
        component: HomeComponent,
        children: [],
        data: {title: 'Página Inicial'}
      },
      {
        path: 'tickets',
        data: {title: 'Ações'},
        loadChildren: () => import('./warning/warning.module').then(m => m.WarningModule),
      },
      {
        path: 'documents',
        // canActivate: [PermissionsGuard],
        data: {
          title: 'Documentos',
          // permissions: [Roles.PERMISSIONS.documents_r]
        },
        loadChildren: () => import('./pages/documents/documents.module').then(m => m.DocumentsModule)
        // resolve: {documents: DocumentsResolverService}
      },
      {
        path: 'people',
        loadChildren: () => import('./pages/people/people.module').then(m => m.PeopleModule)
      },
      {
        path: 'dashboard',
        children: [],
        component: DashboardComponent,
        canActivate: [PermissionsGuard],
        data: {title: 'Métricas', permissions: [Roles.PERMISSIONS.dashboard_r]},
        resolve: {dashboard: DashboardResolverService}
      },
      {
        path: 'dashboard/:dashboard_id',
        children: [],
        component: DashboardComponent,
        canActivate: [PermissionsGuard],
        data: {title: 'Métricas', permissions: [Roles.PERMISSIONS.dashboard_r]},
        resolve: {dashboard: DashboardResolverService}
      },
      {
        path: 'import',
        data: {title: undefined},
        loadChildren: () => import('./pages/data-import/import.module').then(m => m.ImportModule),
      },
      {
        path: 'sub',
        data: {title: undefined, menuItems: []},
        loadChildren: () => import('./pages/submenu/submenu.module').then(m => m.SubmenuModule),
      },
      {
        path: '**', redirectTo: '',
      },
    ],
    data: {
      title: 'Página Inicial',
      icon: 'home'
    }
  },
  {
    path: 'companies',
    component: CompanyPickerComponent,
    canActivate: [AuthGuard, BetaGuard, SamlRedirectGuard, CompanyPickerGuard],
    data: { title: 'Selecione a empresa' },
  },
  {
    path: 'login',
    data: {title: 'Entrar'},
    loadChildren: () => import('./pages/login/login.module').then(m => m.LoginModule)
  },
  {
    path: 'saml-login',
    data: { title: 'Entrar com SAML', },
    loadChildren: () => import('./pages/saml-login/saml-login.module').then(m => m.SamlLoginModule)
  },
  {
    path: '404',
    component: NotFoundComponent,
    data: {title: 'Não encontrada'}
  },
  {
    path: 'restricted',
    component: RestrictedComponent,
    data: {title: 'Acesso restrito'}
  },
  {path: '**', redirectTo: ''}
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    canceledNavigationResolution: 'computed'
  })],
  exports: [RouterModule]
})

export class AppRoutingModule {}
