import { NgZone } from '@angular/core';
import { environment } from '../environments/environment';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { ɵAngularFireSchedulers } from '@angular/fire';

export class AngularFireAuthFactory {
    private platformId: Object;
    private zone: NgZone;
    private angularFireAuth: AngularFireAuth;
    private instances: Object;

    constructor(
        platformId: Object,
        zone: NgZone,
        angularFireAuth: AngularFireAuth,
    ) {
        this.platformId = platformId;
        this.zone = zone;
        this.angularFireAuth = angularFireAuth;
        this.instances = {};
    }

    getInstance(companyId?: string): AngularFireAuth {
        if (!companyId) {
            return this.angularFireAuth;
        }

        if (!Object.keys(this.instances).includes(companyId)) {
            this.instances[companyId] = new AngularFireAuth(environment.firebase, companyId, this.platformId, this.zone, new ɵAngularFireSchedulers(this.zone), null, null, null, null, null, null, null);
        }
        return this.instances[companyId];
    }
}
